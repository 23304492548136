import * as React from 'react'
import { TextField } from '@material-ui/core'
import useStyles from './groupSearch.css'

interface Props {
    onChooseGroup: (group: string) => void
}

const GroupSearch: React.FC<Props> = ({ onChooseGroup }) => {
    const classes = useStyles()
    return (
        <div className={classes.root}>
            <TextField
                fullWidth
                label="Rechercher par nom"
                margin="normal"
                variant="outlined"
                InputProps={{ type: 'search' }}
                onChange={(event) => {
                    onChooseGroup(event.target.value)
                }}
            />
        </div>
    )
}

export default GroupSearch
