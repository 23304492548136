import * as React from 'react'
import { useState } from 'react'
import Banner from '../../components/banner'
import GroupSearch from '../../components/groupSearch'
import CloseButton from '../../components/closeButton'
import { useMediaQuery } from '@material-ui/core'
import useTheme from '@material-ui/core/styles/useTheme'
import DrawerComponent from '../../components/drawer'
import { GroupModel } from '../../core/models/groupModel'
import Navigation from './navigation'
import GroupList from '../groupList'

interface Props {
    handleClose: () => void
    groups: Array<GroupModel>
    pageGroups: Array<GroupModel>
    numPages: number
    currentPage: number
    groupsPerPage: number
}

const Drawer: React.FC<Props> = ({
    handleClose,
    groups,
    currentPage,
    numPages,
    groupsPerPage,
    pageGroups,
}) => {
    const [filter, setFilter] = useState<string>('')
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

    if (filter !== '') {
        groups = groups.filter(
            (group: GroupModel) =>
                group.name.toLocaleLowerCase('fr-FR').search(filter.toLocaleLowerCase('fr-FR')) !== -1,
        )
    }
    return (
        <DrawerComponent>
            {isMobile && <CloseButton handleClose={handleClose} />}
            <Banner />
            <GroupSearch onChooseGroup={(group) => setFilter(group)} />
            {filter !== '' ? (
                <div>
                    <GroupList groups={groups} />
                </div>
            ) : (
                <Navigation
                    groups={groups}
                    currentPage={currentPage}
                    groupsPerPage={groupsPerPage}
                    numPages={numPages}
                />
            )}
        </DrawerComponent>
    )
}

export default Drawer
