import {
    Button,
    CardActions,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    Link,
    Typography,
    useMediaQuery,
} from '@material-ui/core'
import useTheme from '@material-ui/core/styles/useTheme'
import FacebookIcon from '@material-ui/icons/Facebook'
import MailIcon from '@material-ui/icons/Mail'
import PhoneIcon from '@material-ui/icons/Phone'
import { EstablishmentModel } from '@sugg-gestion/react-onvaauresto'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import * as React from 'react'
import { useImgix } from '../core/services/imgix/useImgix'
import { useAddress } from '../core/services/onvaauresto/address/useAddress'
import { usePhoneNumber } from '../core/services/phone/usePhoneNumber'
import CloseButton from './closeButton'
import useStyles from './establishmentOvarDialog.css'
import DeliveryIcon from './icons/DeliveryIcon'
import Loading from './loading'

interface Props {
    open: boolean
    establishment?: EstablishmentModel
    onClose: () => void
    openRestaurantOwnerForm: () => void
}

const EstablishmentOvarDialog: React.FC<Props> = ({
    open,
    onClose,
    establishment,
    openRestaurantOwnerForm,
}) => {
    const classes = useStyles()
    const { getImageUrl } = useImgix()
    const { inlineAddress } = useAddress(establishment?.address)
    const { displayPhone, validPhone, getPhoneNumber } = usePhoneNumber()
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

    const {
        sugg1144: {
            childImageSharp: { fluid: sugg1144 },
        },
        app: {
            childImageSharp: { fluid: app },
        },
    } = useStaticQuery(graphql`
        query {
            sugg1144: file(relativePath: { eq: "sugg1144.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 400) {
                        ...GatsbyImageSharpFluid_withWebp
                        ...GatsbyImageSharpFluidLimitPresentationSize
                    }
                }
            }
            app: file(relativePath: { eq: "app.png" }) {
                childImageSharp {
                    fluid(maxWidth: 88) {
                        ...GatsbyImageSharpFluid_withWebp
                        ...GatsbyImageSharpFluidLimitPresentationSize
                    }
                }
            }
        }
    `)

    return (
        <Dialog
            open={open}
            onClose={onClose}
            classes={{
                paper: classes.root,
            }}
            fullScreen={fullScreen}
            itemScope
            itemType="https://schema.org/Restaurant"
        >
            <CloseButton handleClose={onClose} fullScreen={fullScreen} />
            <DialogTitle disableTypography itemProp="name">
                <Typography variant="h6" component="h1">
                    Restaurant {establishment?.name} à {establishment?.address.city}
                </Typography>
            </DialogTitle>
            <DialogContent
                classes={{
                    root: fullScreen ? classes.fullScreenContent : classes.content,
                }}
            >
                {establishment ? (
                    <>
                        <div className={classes.imageRoot}>
                            <div
                                className={classes.imageContainer}
                                itemProp="photo"
                                itemScope
                                itemType="https://schema.org/ImageObject"
                            >
                                {establishment?.photo?.url && (
                                    <img
                                        itemProp="contentUrl"
                                        src={getImageUrl(establishment?.photo?.url, {
                                            w: '600',
                                        })}
                                        alt={establishment?.name}
                                    />
                                )}
                                <div>
                                    <Typography
                                        classes={{
                                            root: classes.copyright,
                                        }}
                                    >
                                        Tous droits réservés. Source : Facebook
                                    </Typography>
                                    {(establishment?.photo?.admin?.givenName ||
                                        establishment?.photo?.admin?.familyName) && (
                                        <Typography
                                            classes={{
                                                root: classes.author,
                                            }}
                                        >
                                            <span className={classes.heart}>❤</span>️ Publié grâce à l'aide de{' '}
                                            {establishment?.photo?.admin?.givenName}
                                        </Typography>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className={classes.titleContainer}>
                            <Typography
                                classes={{
                                    root: classes.name,
                                }}
                                component="h2"
                            >
                                {establishment.name}
                            </Typography>
                            <Typography
                                variant="body2"
                                classes={{
                                    root: classes.address,
                                }}
                                itemProp="address"
                                itemScope
                                itemType="https://schema.org/PostalAddress"
                            >
                                {inlineAddress()}
                            </Typography>
                        </div>

                        {establishment.description && (
                            <>
                                <Typography
                                    classes={{
                                        root: classes.title,
                                    }}
                                >
                                    Le restaurant
                                </Typography>
                                <DialogContent>
                                    <Typography
                                        variant="body2"
                                        classes={{
                                            root: classes.description,
                                        }}
                                    >
                                        {establishment.description}
                                    </Typography>
                                </DialogContent>
                            </>
                        )}

                        {(validPhone(establishment.phone) ||
                            establishment.email ||
                            establishment.delivery ||
                            establishment.facebookPage) && (
                            <>
                                <Typography
                                    classes={{
                                        root: classes.title,
                                    }}
                                >
                                    Contacter le restaurant
                                </Typography>
                                <CardActions
                                    classes={{
                                        root: classes.actions,
                                    }}
                                >
                                    {validPhone(establishment.phone) && (
                                        <Button
                                            variant="outlined"
                                            startIcon={<PhoneIcon color="primary" />}
                                            href={getPhoneNumber(establishment.phone)}
                                            itemProp="telephone"
                                        >
                                            {displayPhone(establishment.phone)}
                                        </Button>
                                    )}
                                    {establishment.email && (
                                        <Button
                                            variant="outlined"
                                            startIcon={<MailIcon color="primary" />}
                                            href={'mailto:' + establishment.email}
                                            classes={{
                                                root: classes.mailButton,
                                            }}
                                            itemProp="email"
                                        >
                                            {establishment.email}
                                        </Button>
                                    )}
                                    {establishment.delivery && (
                                        <Button
                                            variant="outlined"
                                            startIcon={<DeliveryIcon color="primary" />}
                                            href={establishment.delivery}
                                            target="_blank"
                                            rel="noopener"
                                            itemProp="acceptsReservations"
                                        >
                                            Livraison
                                        </Button>
                                    )}
                                    {establishment.facebookPage && (
                                        <Button
                                            variant="outlined"
                                            startIcon={<FacebookIcon />}
                                            href={establishment.facebookPage}
                                            target="_blank"
                                            rel="noopener"
                                            itemProp="url"
                                        >
                                            Facebook
                                        </Button>
                                    )}
                                </CardActions>
                            </>
                        )}
                        <Typography
                            classes={{
                                root: classes.title,
                            }}
                        >
                            Notre Menu tous les jours dans votre poche
                        </Typography>
                        <Grid
                            container
                            classes={{
                                container: classes.sugg1144Container,
                            }}
                        >
                            <Grid
                                item
                                xs={5}
                                classes={{
                                    item: classes.sugg1144ButtonContainer,
                                }}
                            >
                                <Button
                                    variant="outlined"
                                    classes={{
                                        root: classes.sugg1144Button,
                                        label: classes.sugg1144,
                                    }}
                                    href="https://info.onvaauresto.fr/telecharger_lapplication/"
                                    target="_blank"
                                    rel="noopener"
                                >
                                    Utilisez
                                    <span className={classes.useSugg1144Logo}>
                                        <Img alt="Sugg1144" fluid={app} className={classes.useSugg1144Logo} />
                                    </span>
                                </Button>
                            </Grid>
                            <Grid item xs={7}>
                                <Img
                                    alt="Votre Menu du Jour est arrivé"
                                    fluid={sugg1144}
                                    className={classes.useSugg1144Image}
                                />
                            </Grid>
                        </Grid>
                        <Typography className={classes.restaurantOwner}>
                            <Link component="button" onClick={openRestaurantOwnerForm}>
                                Vous êtes le propriétaire ? Prenez la main et ajoutez plus d’informations
                            </Link>
                        </Typography>
                    </>
                ) : (
                    <Loading withLogo={false} />
                )}
            </DialogContent>
        </Dialog>
    )
}

export default EstablishmentOvarDialog
