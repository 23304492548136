import { RouteComponentProps } from '@reach/router'
import { EstablishmentModel as OvarEstablishmentModel } from '@sugg-gestion/react-onvaauresto'
import { EstablishmentModel as RemplisVertEstablishmentModel } from '@sugg-gestion/react-remplisvert'
import { graphql } from 'gatsby'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { compose } from 'redux'
import EstablishmentDetailsDialog from '../components/establishmentDetailsDialog'
import EstablishmentDialog from '../components/establishmentDialog'
import EstablishmentOvarDialog from '../components/establishmentOvarDialog'
import EstablishmentRemplisVertDialog from '../components/establishmentRemplisVertDialog'
import Footer from '../components/footer'
import HubspotDialogForm from '../components/hubspotDialogForm'
import Layout from '../components/layout'
import SupportUs from '../components/supportUs'
import Contact from '../containers/contact'
import Drawer from '../containers/drawer'
import withFacebook from '../containers/hoc/withFacebook'
import withGoogleMap from '../containers/hoc/withGoogleMap'
import MapContainer from '../containers/mapContainer'
import { GroupModel } from '../core/models/groupModel'
import { MenuBoardEstablishmentModel } from '../core/models/menuBoardEstablishmentModel'
import { useEstablishments as useOvarEstablishments } from '../core/services/onvaauresto/establishments/useEstablishments'
import { useEstablishments as useRemplisVertEstablishments } from '../core/services/remplisVert/establishments/useEstablishments'
import actions from '../core/store/actions'
import { ApplicationState } from '../core/store/reducers'

interface Props extends RouteComponentProps {
    data: {
        filteredGroups: {
            nodes: Array<GroupModel>
        }
        allGroup: {
            nodes: Array<GroupModel>
        }
        file: {
            publicURL: string
        }
    }
    pageContext: {
        numPages: number
        currentPage: number
        groupsPerPage: number
        currentOvarEstablishment: OvarEstablishmentModel
        currentRemplisVertEstablishment: RemplisVertEstablishmentModel
    }
    id?: string
}

const Home: React.FC<Props> = ({ data, pageContext: { numPages, currentPage, groupsPerPage }, id }) => {
    const {
        currentOvarEstablishment,
        currentSuggProEstablishment,
        currentRemplisVertEstablishment,
    } = useSelector(({ app }: ApplicationState) => ({
        currentOvarEstablishment: app.currentOvarEstablishment,
        currentSuggProEstablishment: app.currentSuggProEstablishment,
        currentRemplisVertEstablishment: app.currentRemplisVertEstablishment,
    }))
    const {
        getSurroundingEstablishmentsInBounds: getSurroundingOvarEstablishmentsInBounds,
        establishments: ovarSourceEstablishments,
    } = useOvarEstablishments()
    const {
        getSurroundingEstablishmentsInBounds: getSurroundingRemplisVertEstablishmentsInBounds,
        establishments: remplisVertSourceEstablishments,
    } = useRemplisVertEstablishments()
    const dispatch = useDispatch()
    const [showEstablishmentDetails, setShowEstablishmentDetails] = useState<MenuBoardEstablishmentModel>()
    const [formOpen, setFormOpen] = useState<
        | 'ff0aa335-25fc-42f8-937f-3b72673f58b8'
        | '62ef4bd7-f49f-4e63-91fa-edeb1f6e708d'
        | '83bd81f3-d3c2-4fb4-8aae-7b19276969ed'
        | 'b3879688-fb84-4b9f-a1ef-2ca10176376a'
        | undefined
    >(undefined)

    const {
        allGroup: { nodes: groups },
        filteredGroups: { nodes: filteredGroups },
        file: { publicURL: fullBanner },
    } = data
    let nextPage = undefined
    if (currentPage < numPages) {
        nextPage = '/' + (currentPage + 1)
    }
    let previousPage = undefined
    if (currentPage > 1) {
        previousPage = '/' + (currentPage - 1)
    }

    const handleOpenDetails = () => {
        setShowEstablishmentDetails(currentSuggProEstablishment)
    }

    const handleCloseDetails = () => {
        setShowEstablishmentDetails(undefined)
    }
    const handleClose = () => {
        setFormOpen(undefined)
    }

    useEffect(() => {
        if (id && ovarSourceEstablishments && currentOvarEstablishment === undefined) {
            const currentEstablishment = ovarSourceEstablishments.find(
                (establishment) => establishment.uri === id,
            )
            if (currentEstablishment) {
                const defaultPosition: google.maps.LatLngLiteral = {
                    lat: currentEstablishment.location.latitude,
                    lng: currentEstablishment.location.longitude,
                }
                dispatch(actions.setZoom(16))
                dispatch(actions.setDefaultPosition(defaultPosition))
                dispatch(actions.setCurrentOvarEstablishment(currentEstablishment))
            }
        }
    }, [currentOvarEstablishment, dispatch, id, ovarSourceEstablishments])

    useEffect(() => {
        if (id && remplisVertSourceEstablishments && currentRemplisVertEstablishment === undefined) {
            const currentEstablishment = remplisVertSourceEstablishments.find(
                (establishment) => establishment.uri === id,
            )
            if (currentEstablishment) {
                const defaultPosition: google.maps.LatLngLiteral = {
                    lat: currentEstablishment.location.latitude,
                    lng: currentEstablishment.location.longitude,
                }
                dispatch(actions.setZoom(16))
                dispatch(actions.setDefaultPosition(defaultPosition))
                dispatch(actions.setCurrentRemplisVertEstablishment(currentEstablishment))
            }
        }
    }, [currentRemplisVertEstablishment, dispatch, id, remplisVertSourceEstablishments])

    return (
        <Layout
            drawer={(handleClose) => (
                <Drawer
                    handleClose={handleClose}
                    pageGroups={filteredGroups}
                    groups={groups}
                    currentPage={currentPage}
                    numPages={numPages}
                    groupsPerPage={groupsPerPage}
                />
            )}
            bottomBar={
                <Footer
                    rightContent={
                        <SupportUs href="https://www.facebook.com/On-Va-Au-Resto-103869281537141" />
                    }
                >
                    <Contact />
                </Footer>
            }
        >
            {currentOvarEstablishment ? (
                <Helmet>
                    <title>
                        {'Restaurant ' +
                            currentOvarEstablishment.name +
                            ' à ' +
                            currentOvarEstablishment.address.city +
                            ' | Onvaauresto, trouvez le resto le + proche ! '}
                    </title>
                    <meta
                        property="og:title"
                        content={
                            'Restaurant ' +
                            currentOvarEstablishment.name +
                            ' à ' +
                            currentOvarEstablishment.address.city +
                            ' | Onvaauresto, trouvez le resto le + proche ! '
                        }
                    />
                    <meta
                        name="description"
                        content={
                            'Découvrez le menu du jour de votre restaurant ' +
                            currentOvarEstablishment.name +
                            ' à ' +
                            currentOvarEstablishment.address.city +
                            '. Grâce à Onvaauresto.fr, choisissez le menu du jour de vos restos préférés proches de chez vous !'
                        }
                    />
                    <meta
                        property="og:description"
                        content={
                            'Découvrez le menu du jour de votre restaurant ' +
                            currentOvarEstablishment.name +
                            ' à ' +
                            currentOvarEstablishment.address.city +
                            '. Grâce à Onvaauresto.fr, choisissez le menu du jour de vos restos préférés proches de chez vous !'
                        }
                    />
                    {currentOvarEstablishment.photo && (
                        <meta property="og:image" content={currentOvarEstablishment.photo.url} />
                    )}
                </Helmet>
            ) : currentRemplisVertEstablishment ? (
                <Helmet>
                    <title>
                        {currentRemplisVertEstablishment.categoryName +
                            ' ' +
                            currentRemplisVertEstablishment.name +
                            ' à ' +
                            currentRemplisVertEstablishment.address.city +
                            ' | RemplisVert, remplis ton verre, sauve ta terre ! '}
                    </title>
                    <meta
                        property="og:title"
                        content={
                            currentRemplisVertEstablishment.categoryName +
                            ' ' +
                            currentRemplisVertEstablishment.name +
                            ' à ' +
                            currentRemplisVertEstablishment.address.city +
                            ' | RemplisVert, remplis ton verre, sauve ta terre ! '
                        }
                    />
                    <meta
                        name="description"
                        content={
                            "Découvrez l'actu de votre " +
                            currentRemplisVertEstablishment.categoryName.toLowerCase() +
                            ' ' +
                            currentRemplisVertEstablishment.name +
                            ' à ' +
                            currentRemplisVertEstablishment.address.city +
                            '. Grâce à Onvaauresto.fr, remplis ton verre, sauve ta terre !'
                        }
                    />
                    <meta
                        property="og:description"
                        content={
                            "Découvrez l'actu de votre " +
                            currentRemplisVertEstablishment.categoryName.toLowerCase() +
                            ' ' +
                            currentRemplisVertEstablishment.name +
                            ' à ' +
                            currentRemplisVertEstablishment.address.city +
                            '. Grâce à Onvaauresto.fr, remplis ton verre, sauve ta terre !'
                        }
                    />
                    {currentRemplisVertEstablishment.photo && (
                        <meta property="og:image" content={currentRemplisVertEstablishment.photo.url} />
                    )}
                </Helmet>
            ) : (
                <Helmet>
                    <meta property="og:image" content={fullBanner} />
                    {previousPage && <link rel="prev" href={previousPage} />}
                    {nextPage && <link rel="next" href={nextPage} />}
                </Helmet>
            )}

            <MapContainer
                groups={groups}
                id={id}
                getSurroundingOvarEstablishmentsInBounds={getSurroundingOvarEstablishmentsInBounds}
                getSurroundingRemplisVertEstablishmentsInBounds={
                    getSurroundingRemplisVertEstablishmentsInBounds
                }
                ovarSourceEstablishments={ovarSourceEstablishments}
                remplisVertSourceEstablishments={remplisVertSourceEstablishments}
            />

            <EstablishmentDialog
                open={currentSuggProEstablishment !== undefined}
                establishment={currentSuggProEstablishment}
                onClose={() => dispatch(actions.setCurrentSuggProEstablishment(undefined))}
                onOpenDetails={handleOpenDetails}
            />
            <EstablishmentDetailsDialog
                open={showEstablishmentDetails !== undefined}
                establishment={showEstablishmentDetails}
                onClose={() => {
                    handleCloseDetails()
                    dispatch(actions.setCurrentSuggProEstablishment(undefined))
                }}
                onBack={handleCloseDetails}
            />
            <EstablishmentOvarDialog
                open={currentOvarEstablishment !== undefined}
                establishment={currentOvarEstablishment}
                onClose={() => dispatch(actions.setCurrentOvarEstablishment(undefined))}
                openRestaurantOwnerForm={() => {
                    setFormOpen('b3879688-fb84-4b9f-a1ef-2ca10176376a')
                }}
            />
            <EstablishmentRemplisVertDialog
                open={currentRemplisVertEstablishment !== undefined}
                establishment={currentRemplisVertEstablishment}
                onClose={() => dispatch(actions.setCurrentRemplisVertEstablishment(undefined))}
            />
            {formOpen && <HubspotDialogForm open={true} formId={formOpen} onClose={handleClose} />}
        </Layout>
    )
}

export const query = graphql`
    query($skip: Int!, $limit: Int!) {
        filteredGroups: allGroup(sort: { fields: internalName }, limit: $limit, skip: $skip) {
            nodes {
                name
                facebookGroupId
                memberCount
                latitude
                longitude
            }
        }
        allGroup(sort: { fields: internalName }) {
            nodes {
                name
                facebookGroupId
                memberCount
                latitude
                longitude
                radius
                cover {
                    source
                }
            }
        }
        file(relativePath: { eq: "banner.jpg" }) {
            publicURL
        }
    }
`

export default compose(withGoogleMap, withFacebook)(Home)
