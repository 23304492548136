import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        link: {
            'textTransform': 'none',
            'textAlign': 'center',
            'backgroundColor': '#3b5998',
            'height': 28,
            '&:hover': {
                backgroundColor: '#3b5998',
            },
            '& .MuiButton-iconSizeMedium > *:first-child': {
                marginTop: -14,
                marginBottom: -14,
                marginLeft: -12,
                marginRight: 0,
                fontSize: 28,
            },
        },
        like: {
            width: 130,
        },
    }),
)

export default useStyles
