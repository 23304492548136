import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            maxWidth: '100%',
            width: 600,
            height: 800,
            maxHeight: '100%',
        },
        content: {
            'position': 'relative',
            '& iframe': {
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                borderStyle: 'none',
            },
        },
        fullScreenContent: {
            'display': 'flex',
            'flexDirection': 'column',
            'padding': theme.spacing(0, 0),
            '& iframe': {
                flexGrow: 1,
                borderStyle: 'none',
            },
        },
        moreInfo: {
            textTransform: 'none',
            marginLeft: theme.spacing(2),
        },
        // title: {
        //     color: theme.palette.text.primary,
        //     cursor: 'pointer',
        // },
        titleDetails: {
            paddingLeft: theme.spacing(6),
        },
        back: {
            position: 'absolute',
            top: theme.spacing(),
            left: 0,
        },
        titleContainer: {
            margin: theme.spacing(2, 0),
            [theme.breakpoints.down('sm')]: {
                margin: theme.spacing(2, 2),
            },
        },
        imageRoot: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        imageContainer: {
            'display': 'inline-block',
            '& > img': {
                maxWidth: '100%',
                maxHeight: '80vh',
                display: 'block',
                margin: '0 auto',
            },
        },
        name: {
            fontSize: '1.2rem',
            fontWeight: 600,
        },
        category: {
            fontSize: '1rem',
            fontWeight: 400,
        },
        address: {
            fontStyle: 'italic',
        },
        copyright: {
            float: 'left',
            fontStyle: 'italic',
            textAlign: 'left',
            fontSize: '0.6rem',
            paddingTop: theme.spacing(0.5),
        },
        author: {
            fontStyle: 'italic',
            textAlign: 'right',
            fontSize: '0.8rem',
        },
        heart: {
            fontStyle: 'normal',
        },
        description: {
            whiteSpace: 'pre-line',
        },
        title: {
            margin: theme.spacing(1, 0),
            padding: theme.spacing(1, 3),
            backgroundColor: '#F0F0F0',
            [theme.breakpoints.down('xs')]: {
                fontSize: '0.9rem',
            },
        },
        actions: {
            'justifyContent': 'center',
            'flexWrap': 'wrap',
            '& .MuiButton-root': {
                marginBottom: theme.spacing(0.5),
            },
        },
        mailButton: {
            textTransform: 'none',
        },
        sugg1144Container: {
            paddingBottom: theme.spacing(),
            paddingLeft: theme.spacing(),
            paddingRight: theme.spacing(),
        },
        sugg1144ButtonContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: theme.spacing(),
        },
        sugg1144Button: {
            [theme.breakpoints.down('xs')]: {
                width: 160,
                paddingLeft: 0,
                paddingRight: 0,
            },
        },
        sugg1144: {
            textTransform: 'none',
            flexWrap: 'wrap',
            fontSize: '1rem',
            paddingRight: 96,
            padding: theme.spacing(1, 0),
            position: 'relative',
            [theme.breakpoints.down('xs')]: {
                paddingRight: 50,
            },
        },
        useSugg1144Logo: {
            width: 88,
            position: 'absolute',
            right: 0,
            [theme.breakpoints.down('xs')]: {
                width: 46,
                right: 2,
            },
        },
        restaurantOwner: {
            'marginTop': theme.spacing(2),
            'marginBottom': theme.spacing(1),
            'textAlign': 'center',
            '& button': {
                textDecoration: 'underline',
            },
        },
        useSugg1144Image: {
            maxWidth: '100%',
        },
        useRemplisVertImage: {
            maxWidth: '100%',
            marginTop: theme.spacing(2),
        },
    }),
)

export default useStyles
