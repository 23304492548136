import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
        },
        facebook: {
            'display': 'inline-flex',
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            },
            'backgroundColor': '#3b5998',
            'textTransform': 'none',
            'color': theme.palette.common.white,
            '&:hover': {
                backgroundColor: 'rgba(59, 89, 152, .8)',
            },
        },
        facebookMobile: {
            'display': 'none',
            [theme.breakpoints.down('sm')]: {
                display: 'inline-flex',
            },
            'textTransform': 'none',
            '& .MuiButton-startIcon': {
                color: '#3b5998',
            },
            '& .MuiButton-label': {
                textAlign: 'center',
                fontSize: '0.8rem',
            },
        },
        link: {
            display: 'inline-flex',
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            },
            fontSize: '1.1rem',
            fontWeight: 600,
            marginRight: theme.spacing(1),
            textAlign: 'center',
            color: theme.palette.primary.contrastText,
        },
    }),
)

export default useStyles
