import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
        },
    }),
)

export default useStyles
