import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        close: {
            position: 'absolute',
            top: theme.spacing(),
            right: theme.spacing(),
            backgroundColor: theme.palette.common.white,
            zIndex: theme.zIndex.drawer + 1,
        },
        fullScreen: {
            top: theme.spacing(2),
            right: theme.spacing(2),
        },
    }),
)

export default useStyles
