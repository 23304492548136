import * as React from 'react'
import { ComponentType } from 'react'
import useGoogleMap from '../../core/services/google-map/useGoogleMap'

const withGoogleMap = (ComposedComponent: ComponentType) => {
    const WithGoogleMap: React.FC = ({ ...props }) => {
        useGoogleMap()
        return <ComposedComponent {...props} />
    }
    return WithGoogleMap
}

export default withGoogleMap
