import * as React from 'react'
import { useEffect, useRef } from 'react'
import useStyles from './hubspotForm.css'

interface Props {
    formId: string
}

const HubspotForm: React.FC<Props> = ({ formId }) => {
    const classes = useStyles()
    const formRef = useRef(null)
    useEffect(() => {
        if (formRef.current != null) {
            formRef.current.innerHTML = ''
            const tag = document.createElement('script')
            tag.innerHTML =
                'hbspt.forms.create({\n' +
                '        portalId: "' +
                process.env.GATSBY_HUBSPOT_PORTAL_ID +
                '",\n' +
                '        formId: "' +
                formId +
                '"\n' +
                '    });'
            formRef.current.appendChild(tag)
        }
    }, [formRef.current])
    return <div ref={formRef} className={classes.root} />
}

export default HubspotForm
