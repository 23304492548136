import * as React from 'react'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import { Button, Typography } from '@material-ui/core'
import useStyles from './businessHours.css'
import { BusinessHourModel, BusinessHoursModel } from '../core/models/businessHoursModel'
import { SpecialClosingModel } from '../core/models/specialClosingModel'
import { useState } from 'react'
import clsx from 'clsx'
import * as moment from 'moment'

interface Props {
    hours?: BusinessHoursModel
    specialClosings?: Array<SpecialClosingModel>
}

const BusinessHours: React.FC<Props> = ({ hours, specialClosings }) => {
    const classes = useStyles()
    const dayOfWeek = new Date().getDay()
    const [expanded, setExpanded] = useState(false)
    const getHours = (hours: Array<BusinessHourModel>) => {
        const hourList = []
        hours.forEach((hour) => {
            hourList.push(hour.startTime + ' - ' + hour.endTime)
        })
        if (hourList.length === 0) {
            return 'Fermé'
        }
        return hourList.join(' / ')
    }
    const getDatesOfWeek = () => {
        const days = []
        const dateStartOfWeek = moment().startOf('week').add(1, 'd')
        const dateEndOfWeek = moment().endOf('week').isoWeekday(7)
        let day = dateStartOfWeek
        while (day <= dateEndOfWeek) {
            days.push(day.toDate())
            day = day.clone().add(1, 'd')
        }
        return days
    }
    const checkIfDateInSpecialClosing = (date: Date) => {
        let val = ''
        const dateMoment = moment(date)
        specialClosings?.forEach((specialClosing) => {
            const dateStartMoment = moment(specialClosing.dateStart)
            const dateEndMoment = moment(specialClosing.dateEnd)
            if (
                (dateMoment.isBefore(dateEndMoment, 'day') && dateMoment.isAfter(dateStartMoment, 'day')) ||
                dateMoment.isSame(dateStartMoment, 'day') ||
                dateMoment.isSame(dateEndMoment, 'day')
            ) {
                val = specialClosing.label
                return
            }
        })
        return val
    }
    const datesOfWeek: Array<Date> = getDatesOfWeek()
    const getHoursOfDay = () => {
        const isDateInSpecialClosing = checkIfDateInSpecialClosing(datesOfWeek[(dayOfWeek % 7) - 1])
        if (isDateInSpecialClosing !== '') {
            return isDateInSpecialClosing
        }
        switch (dayOfWeek) {
            case 0:
                return getHours(hours.sunday ?? [])
            case 1:
                return getHours(hours.monday ?? [])
            case 2:
                return getHours(hours.tuesday ?? [])
            case 3:
                return getHours(hours.wednesday ?? [])
            case 4:
                return getHours(hours.thursday ?? [])
            case 5:
                return getHours(hours.friday ?? [])
            case 6:
                return getHours(hours.saturday ?? [])
        }
        return getHours(hours.sunday ?? [])
    }
    const getDayOfWeek = (dayOfWeek: number) => {
        switch (dayOfWeek) {
            case 0:
                return 'Dimanche'
            case 1:
                return 'Lundi'
            case 2:
                return 'Mardi'
            case 3:
                return 'Mercredi'
            case 4:
                return 'Jeudi'
            case 5:
                return 'Vendredi'
            case 6:
                return 'Samedi'
        }
        return 'Lundi'
    }
    const Container: React.FC = ({ children }) => <span className={classes.container}>{children}</span>
    const Line: React.FC = ({ children }) => <span className={classes.line}>{children}</span>

    const days = []
    let i = 1
    const hoursComplete = {
        monday: hours.monday,
        tuesday: hours.tuesday,
        wednesday: hours.wednesday,
        thursday: hours.thursday,
        friday: hours.friday,
        saturday: hours.saturday,
        sunday: hours.sunday,
    }
    for (const hour in hoursComplete) {
        const isDateInSpecialClosing = checkIfDateInSpecialClosing(datesOfWeek[i - 1])
        if (hoursComplete.hasOwnProperty(hour)) {
            if (isDateInSpecialClosing !== '') {
                days.push({
                    label: getDayOfWeek(i % 7),
                    value: isDateInSpecialClosing,
                    today: i === dayOfWeek,
                })
            } else {
                days.push({
                    label: getDayOfWeek(i % 7),
                    value: getHours(hoursComplete[hour as keyof BusinessHoursModel] ?? []),
                    today: i === dayOfWeek,
                })
            }
            i++
        }
    }
    return (
        <Button
            startIcon={<AccessTimeIcon />}
            endIcon={expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            classes={{
                root: classes.businessHours,
            }}
            onClick={() => setExpanded(!expanded)}
        >
            <Container>
                {expanded ? (
                    days.map((day, index) => (
                        <Line key={index}>
                            <Typography
                                classes={{
                                    root: clsx(classes.day, {
                                        [classes.today]: day.today,
                                    }),
                                }}
                            >
                                {day.label}
                            </Typography>
                            <Typography
                                classes={{
                                    root: classes.hours,
                                }}
                            >
                                {day.value}
                            </Typography>
                        </Line>
                    ))
                ) : (
                    <Line>
                        <Typography
                            classes={{
                                root: clsx(classes.day, classes.today),
                            }}
                        >
                            Aujourd'hui
                        </Typography>
                        <Typography
                            classes={{
                                root: classes.hours,
                            }}
                        >
                            {getHoursOfDay()}
                        </Typography>
                    </Line>
                )}
            </Container>
        </Button>
    )
}

export default BusinessHours
