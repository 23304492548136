import * as React from 'react'
import MapOverlayContainer from '../components/mapOverlayContainer'
import GroupBanner from '../components/groupBanner'
import { useSelector } from 'react-redux'
import { ApplicationState } from '../core/store/reducers'

const MapOverlay: React.FC = () => {
    const { currentGroup } = useSelector(({ app }: ApplicationState) => ({
        currentGroup: app.currentGroup,
    }))
    return (
        <>
            {currentGroup && (
                <MapOverlayContainer>
                    <GroupBanner group={currentGroup} />
                </MapOverlayContainer>
            )}
        </>
    )
}

export default MapOverlay
