import * as React from 'react'
import { ComponentType, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { GroupModel } from '../core/models/groupModel'
import useTheme from '@material-ui/core/styles/useTheme'
import useStyles from './mapComponent.css'
import { compose } from 'redux'
import withGoogleMap from '../containers/hoc/withGoogleMap'
import { MenuBoardEstablishmentModel } from '../core/models/menuBoardEstablishmentModel'
import MarkerClusterer from '@googlemaps/markerclustererplus'
import { EstablishmentModel as OvarEstablishmentModel } from '@sugg-gestion/react-onvaauresto'
import { EstablishmentModel as RemplisVertEstablishmentModel } from '@sugg-gestion/react-remplisvert'

export interface MapComponentProps {
    groups: Array<GroupModel>
    defaultZoom?: number
    defaultPosition?: google.maps.LatLngLiteral
    onZoom?: (map: google.maps.Map) => void
    onBoundsChanged?: (map: google.maps.Map) => void
    onDragend?: (map: google.maps.Map) => void
    onInit?: (map: google.maps.Map) => void
    onClickZone?: (map: google.maps.Map, group: GroupModel, groupBounds: google.maps.LatLngBounds) => void
    onClickSuggProMarker?: (establishment: MenuBoardEstablishmentModel) => void
    suggProEstablishments: Array<MenuBoardEstablishmentModel>
    ovarEstablishments?: Array<OvarEstablishmentModel>
    remplisVertEstablishments?: Array<RemplisVertEstablishmentModel>
    onClickOvarMarker?: (establishment: OvarEstablishmentModel) => void
    onClickRemplisVertMarker?: (establishment: RemplisVertEstablishmentModel) => void
}

const MapComponent: React.FC<MapComponentProps> = ({
    groups,
    onZoom,
    onBoundsChanged,
    onDragend,
    onInit,
    defaultZoom = 6,
    defaultPosition = { lat: 46.8404885, lng: 2.213749 },
    onClickZone,
    onClickSuggProMarker,
    suggProEstablishments,
    ovarEstablishments,
    remplisVertEstablishments,
    onClickOvarMarker,
    onClickRemplisVertMarker,
}) => {
    const theme = useTheme()
    const mapRef = useRef(null)
    const [cluster, setCluster] = useState<MarkerClusterer>()
    const [markers, setMarkers] = useState<Array<google.maps.Marker>>([])
    const [ovarMarkers, setOvarMarkers] = useState<{ [id: string]: google.maps.Marker }>({})
    const [remplisVertMarkers, setRemplisVertMarkers] = useState<{ [id: string]: google.maps.Marker }>({})
    const classes = useStyles()

    const registerSuggProEstablishments = useCallback(
        (establishments: Array<MenuBoardEstablishmentModel>) => {
            const iconMenuKing = {
                url: '/images/pin-menu-king-a.png',
                scaledSize: new google.maps.Size(45, 55),
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(22, 55),
            }
            const iconMenuKingRemplisVert = {
                url: '/images/pin-menu-remplisvert-king.png',
                scaledSize: new google.maps.Size(45, 55),
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(22, 55),
            }
            const markersToAdd: Array<google.maps.Marker> = []
            markers.forEach((marker) => marker.setVisible(false))
            establishments.forEach((establishment) => {
                if (!markers[establishment.id]) {
                    markers[establishment.id] = new google.maps.Marker({
                        position: new google.maps.LatLng(
                            establishment.location.lat,
                            establishment.location.lng,
                        ),
                        visible: true,
                        icon: establishment.remplisVert ? iconMenuKingRemplisVert : iconMenuKing,
                    })
                    if (onClickSuggProMarker) {
                        google.maps.event.addListener(markers[establishment.id], 'click', () => {
                            onClickSuggProMarker(establishment)
                        })
                    }
                    markersToAdd.push(markers[establishment.id])
                } else {
                    markers[establishment.id].setVisible(true)
                }
            })
            setMarkers(markers)
            return markersToAdd
        },
        [markers, onClickSuggProMarker],
    )

    const registerOvarEstablishments = useCallback(
        (ovarEstablishments: Array<OvarEstablishmentModel>) => {
            const iconMenuRemplisVert = {
                url: '/images/pin-menu-remplisvert.png',
                scaledSize: new google.maps.Size(40, 40),
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(20, 40),
            }

            const iconMenu = {
                url: '/images/pin-menu.png',
                scaledSize: new google.maps.Size(40, 40),
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(20, 40),
            }
            const markersToAdd: Array<google.maps.Marker> = []
            for (let id in ovarMarkers) {
                if (ovarMarkers.hasOwnProperty(id)) {
                    const marker = ovarMarkers[id]
                    marker.setVisible(false)
                }
            }
            ovarEstablishments.forEach((establishment) => {
                if (!ovarMarkers[establishment.id]) {
                    ovarMarkers[establishment.id] = new google.maps.Marker({
                        position: new google.maps.LatLng(
                            establishment.location.latitude,
                            establishment.location.longitude,
                        ),
                        visible: true,
                        icon: establishment.remplisVert ? iconMenuRemplisVert : iconMenu,
                    })
                    if (onClickOvarMarker) {
                        google.maps.event.addListener(ovarMarkers[establishment.id], 'click', () => {
                            onClickOvarMarker(establishment)
                        })
                    }
                    markersToAdd.push(ovarMarkers[establishment.id])
                } else {
                    ovarMarkers[establishment.id].setVisible(true)
                }
            })
            setOvarMarkers(ovarMarkers)
            return markersToAdd
        },
        [onClickOvarMarker, ovarMarkers],
    )

    const registerRemplisVertEstablishments = useCallback(
        (remplisVertEstablishments: Array<RemplisVertEstablishmentModel>) => {
            const iconRemplisVert = {
                url: '/images/pin-fountain.png',
                scaledSize: new google.maps.Size(40, 40),
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(20, 40),
            }
            const markersToAdd: Array<google.maps.Marker> = []
            for (let id in remplisVertMarkers) {
                if (remplisVertMarkers.hasOwnProperty(id)) {
                    const marker = remplisVertMarkers[id]
                    marker.setVisible(false)
                }
            }
            remplisVertEstablishments.forEach((establishment) => {
                if (!remplisVertMarkers[establishment.id]) {
                    remplisVertMarkers[establishment.id] = new google.maps.Marker({
                        position: new google.maps.LatLng(
                            establishment.location.latitude,
                            establishment.location.longitude,
                        ),
                        visible: true,
                        icon: iconRemplisVert,
                    })
                    if (onClickRemplisVertMarker) {
                        google.maps.event.addListener(remplisVertMarkers[establishment.id], 'click', () => {
                            onClickRemplisVertMarker(establishment)
                        })
                    }
                    markersToAdd.push(remplisVertMarkers[establishment.id])
                } else {
                    remplisVertMarkers[establishment.id].setVisible(true)
                }
            })
            setRemplisVertMarkers(remplisVertMarkers)
            return markersToAdd
        },
        [onClickRemplisVertMarker, remplisVertMarkers],
    )

    useEffect(() => {
        if (mapRef.current != null) {
            const myStyles: Array<google.maps.MapTypeStyle> = [
                {
                    featureType: 'poi.business',
                    elementType: 'labels',
                    stylers: [{ visibility: 'off' }],
                },
            ]
            const map = new google.maps.Map(mapRef.current as HTMLElement, {
                zoom: defaultZoom,
                center: defaultPosition,
                mapTypeId: 'terrain',
                mapTypeControl: false,
                fullscreenControl: false,
                styles: myStyles,
            })
            if (onZoom) {
                map.addListener('zoom_changed', () => {
                    onZoom(map)
                    const zoom = map.getZoom()
                    if (zoom > 13) {
                        cityMarkers.forEach((city) => {
                            city.setVisible(false)
                        })
                    } else {
                        cityMarkers.forEach((city) => {
                            city.setVisible(true)
                        })
                    }
                })
            }
            if (onBoundsChanged) {
                map.addListener('bounds_changed', () => onBoundsChanged(map))
            }
            if (onDragend) {
                map.addListener('dragend', () => onDragend(map))
            }
            if (onInit) {
                google.maps.event.addListenerOnce(map, 'idle', function () {
                    onInit(map)
                })
            }
            const cityMarkers: Array<google.maps.Circle> = []
            groups.forEach((group) => {
                const city = new google.maps.Circle({
                    strokeColor: theme.palette.primary.dark,
                    strokeOpacity: 0.8,
                    strokeWeight: 1,
                    fillColor: theme.palette.primary.main,
                    fillOpacity: 0.35,
                    map,
                    center: {
                        lat: group.latitude,
                        lng: group.longitude,
                    },
                    radius: group.radius * 1000,
                    clickable: true,
                })
                cityMarkers.push(city)
                if (onClickZone) {
                    google.maps.event.addListener(city, 'click', () => {
                        onClickZone(map, group, city.getBounds())
                    })
                }
            })
            const markers = registerSuggProEstablishments(suggProEstablishments)
            const ovarMarkers =
                ovarEstablishments !== undefined ? registerOvarEstablishments(ovarEstablishments) : []
            const remplisVertMarkers =
                remplisVertEstablishments !== undefined
                    ? registerRemplisVertEstablishments(remplisVertEstablishments)
                    : []
            const cluster = new MarkerClusterer(map, [...markers, ...ovarMarkers, ...remplisVertMarkers], {
                ignoreHidden: true,
                enableRetinaIcons: true,
                imagePath: '/images/m',
            })
            setCluster(cluster)
        }
    }, [mapRef.current])

    useEffect(() => {
        if (cluster) {
            const markersToAdd = registerSuggProEstablishments(suggProEstablishments)
            cluster.addMarkers(markersToAdd)
        }
    }, [cluster, registerSuggProEstablishments, suggProEstablishments])

    useEffect(() => {
        if (cluster && ovarEstablishments !== undefined) {
            const markersToAdd = registerOvarEstablishments(ovarEstablishments)
            cluster.addMarkers(markersToAdd)
        }
    }, [cluster, ovarEstablishments, ovarMarkers, registerOvarEstablishments])

    useEffect(() => {
        if (cluster && remplisVertEstablishments !== undefined) {
            const markersToAdd = registerRemplisVertEstablishments(remplisVertEstablishments)
            cluster.addMarkers(markersToAdd)
        }
    }, [cluster, remplisVertEstablishments, remplisVertMarkers, registerRemplisVertEstablishments])

    return <div ref={mapRef} className={classes.root} />
}

export default compose<ComponentType<MapComponentProps>>(withGoogleMap)(MapComponent)
