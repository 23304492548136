import { AddressModel } from '@sugg-gestion/react-onvaauresto'
import * as React from 'react'

export const useAddress = (address?: AddressModel) => {
    const inlineAddress = () => {
        const list = []
        if (address.line1) {
            list.push(<span itemProp="streetAddress">{address.line1}</span>)
        }
        if (address.line2) {
            list.push(address.line2)
        }
        if (address.zipCode) {
            list.push(<span itemProp="postalCode">{address.zipCode}</span>)
        }
        if (address.city) {
            list.push(<span itemProp="addressLocality">{address.city}</span>)
        }
        return (
            <>
                {list.map((item, index) => (
                    <React.Fragment key={'address-' + index}>{item} </React.Fragment>
                ))}
            </>
        )
    }

    return {
        inlineAddress,
    }
}
