import { Button, CardActions, DialogContent, Grid, Typography } from '@material-ui/core'
import FacebookIcon from '@material-ui/icons/Facebook'
import InstagramIcon from '@material-ui/icons/Instagram'
import LanguageIcon from '@material-ui/icons/Language'
import MailIcon from '@material-ui/icons/Mail'
import PhoneIcon from '@material-ui/icons/Phone'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import * as React from 'react'
import { MenuBoardEstablishmentModel } from '../core/models/menuBoardEstablishmentModel'
import { useAddress } from '../core/services/address/useAddress'
import { useImgix } from '../core/services/imgix/useImgix'
import { usePhoneNumber } from '../core/services/phone/usePhoneNumber'
import { useEstablishment } from '../core/services/sugg1144/useEstablishment'
import BusinessHours from './businessHours'
import useStyles from './establishmentDetails.css'
import BookingIcon from './icons/BookingIcon'
import DeliveryIcon from './icons/DeliveryIcon'
import Loading from './loading'

interface Props {
    menuBoardEstablishment: MenuBoardEstablishmentModel
}

const EstablishmentDetails: React.FC<Props> = ({ menuBoardEstablishment }) => {
    const { establishment, inProgress } = useEstablishment(menuBoardEstablishment.id)
    const classes = useStyles()
    const { getImageUrl } = useImgix()
    const { inlineAddress } = useAddress(establishment?.completeAddress)
    const { displayPhone, validPhone, getPhoneNumber } = usePhoneNumber()
    const {
        sugg1144: {
            childImageSharp: { fluid: sugg1144 },
        },
        app: {
            childImageSharp: { fluid: app },
        },
    } = useStaticQuery(graphql`
        query {
            sugg1144: file(relativePath: { eq: "sugg1144.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 400) {
                        ...GatsbyImageSharpFluid_withWebp
                        ...GatsbyImageSharpFluidLimitPresentationSize
                    }
                }
            }
            app: file(relativePath: { eq: "app.png" }) {
                childImageSharp {
                    fluid(maxWidth: 88) {
                        ...GatsbyImageSharpFluid_withWebp
                        ...GatsbyImageSharpFluidLimitPresentationSize
                    }
                }
            }
        }
    `)
    return (
        <div>
            {inProgress ? (
                <Loading withLogo={false} />
            ) : establishment ? (
                <>
                    <div
                        className={classes.imageContainer}
                        style={
                            establishment.picture
                                ? {
                                      backgroundImage:
                                          'url("' +
                                          getImageUrl(establishment.picture, {
                                              w: '600',
                                              h: '240',
                                              fit: 'crop',
                                              crop: 'edge',
                                          }) +
                                          '")',
                                  }
                                : { backgroundImage: 'url("/images/defaultPhoto.png")' }
                        }
                        title={establishment.name}
                    />
                    <DialogContent>
                        <Typography
                            classes={{
                                root: classes.name,
                            }}
                        >
                            {establishment.name}
                        </Typography>
                        <Typography
                            variant="body2"
                            classes={{
                                root: classes.address,
                            }}
                        >
                            {inlineAddress()}
                        </Typography>
                        {establishment.businessHours && (
                            <BusinessHours
                                hours={establishment.businessHours}
                                specialClosings={establishment.specialClosing}
                            />
                        )}
                    </DialogContent>

                    <Typography
                        classes={{
                            root: classes.title,
                        }}
                    >
                        Le restaurant
                    </Typography>
                    <DialogContent>
                        <Typography
                            variant="body2"
                            classes={{
                                root: classes.description,
                            }}
                        >
                            {establishment.description}
                        </Typography>
                    </DialogContent>
                    {(validPhone(establishment.phoneNumber) ||
                        establishment.email ||
                        establishment.website ||
                        establishment.delivery ||
                        establishment.booking) && (
                        <>
                            <Typography
                                classes={{
                                    root: classes.title,
                                }}
                            >
                                Contacter - Commander - Réserver
                            </Typography>
                            <CardActions
                                classes={{
                                    root: classes.actions,
                                }}
                            >
                                {validPhone(establishment.phoneNumber) && (
                                    <Button
                                        variant="outlined"
                                        startIcon={<PhoneIcon color="primary" />}
                                        href={getPhoneNumber(establishment.phoneNumber)}
                                    >
                                        {displayPhone(establishment.phoneNumber)}
                                    </Button>
                                )}
                                {establishment.email && (
                                    <Button
                                        variant="outlined"
                                        startIcon={<MailIcon color="primary" />}
                                        href={'mailto:' + establishment.email}
                                        classes={{
                                            root: classes.mailButton,
                                        }}
                                    >
                                        {establishment.email}
                                    </Button>
                                )}
                                {establishment.website && (
                                    <Button
                                        variant="outlined"
                                        startIcon={<LanguageIcon color="primary" />}
                                        href={establishment.website}
                                        target="_blank"
                                        rel="noopener"
                                    >
                                        Site web
                                    </Button>
                                )}
                                {establishment.delivery && (
                                    <Button
                                        variant="outlined"
                                        startIcon={<DeliveryIcon color="primary" />}
                                        href={establishment.delivery}
                                        target="_blank"
                                        rel="noopener"
                                    >
                                        Livraison
                                    </Button>
                                )}
                                {establishment.booking && (
                                    <Button
                                        variant="outlined"
                                        startIcon={<BookingIcon color="primary" />}
                                        href={establishment.booking}
                                        target="_blank"
                                        rel="noopener"
                                    >
                                        Réservation
                                    </Button>
                                )}
                            </CardActions>
                        </>
                    )}
                    {(establishment.facebookUrl ||
                        establishment.instagramUrl ||
                        establishment.mealandmeetUrl) && (
                        <>
                            <Typography
                                classes={{
                                    root: classes.title,
                                }}
                            >
                                Suivre les actualités
                            </Typography>
                            <CardActions
                                classes={{
                                    root: classes.actions,
                                }}
                            >
                                {establishment.facebookUrl && (
                                    <Button
                                        variant="outlined"
                                        startIcon={<FacebookIcon />}
                                        href={establishment.facebookUrl}
                                        target="_blank"
                                        rel="noopener"
                                    >
                                        Facebook
                                    </Button>
                                )}
                                {establishment.instagramUrl && (
                                    <Button
                                        variant="outlined"
                                        startIcon={<InstagramIcon />}
                                        href={establishment.instagramUrl}
                                        target="_blank"
                                        rel="noopener"
                                    >
                                        Instagram
                                    </Button>
                                )}
                                {establishment.mealandmeetUrl && (
                                    <Button
                                        variant="outlined"
                                        startIcon={
                                            <img
                                                src="/icons/MnM.png"
                                                alt="Meal and Meet"
                                                width="20"
                                                height="20"
                                            />
                                        }
                                        href={establishment.mealandmeetUrl}
                                        target="_blank"
                                        rel="noopener"
                                    >
                                        Meal and Meet
                                    </Button>
                                )}
                            </CardActions>
                        </>
                    )}
                    <Typography
                        classes={{
                            root: classes.title,
                        }}
                    >
                        Notre Menu tous les jours dans votre poche
                    </Typography>
                    <Grid
                        container
                        classes={{
                            container: classes.sugg1144Container,
                        }}
                    >
                        <Grid
                            item
                            xs={5}
                            classes={{
                                item: classes.sugg1144ButtonContainer,
                            }}
                        >
                            <Button
                                variant="outlined"
                                classes={{
                                    root: classes.sugg1144Button,
                                    label: classes.sugg1144,
                                }}
                                href="https://info.onvaauresto.fr/telecharger_lapplication/"
                                target="_blank"
                                rel="noopener"
                            >
                                Utilisez
                                <span className={classes.useSugg1144Logo}>
                                    <Img alt="Sugg1144" fluid={app} className={classes.useSugg1144Logo} />
                                </span>
                            </Button>
                        </Grid>
                        <Grid item xs={7}>
                            <Img
                                alt="Votre Menu du Jour est arrivé"
                                fluid={sugg1144}
                                className={classes.useSugg1144Image}
                            />
                        </Grid>
                    </Grid>
                </>
            ) : (
                <Loading withLogo={false} />
            )}
        </div>
    )
}

export default EstablishmentDetails
