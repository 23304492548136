import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            maxWidth: '100%',
            width: 600,
            height: 800,
            maxHeight: '100%',
        },
        content: {
            'position': 'relative',
            '& iframe': {
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                borderStyle: 'none',
            },
        },
        fullScreenContent: {
            'display': 'flex',
            'flexDirection': 'column',
            'padding': theme.spacing(0, 0),
            '& iframe': {
                flexGrow: 1,
                borderStyle: 'none',
            },
        },
        moreInfo: {
            textTransform: 'none',
            marginLeft: theme.spacing(2),
        },
        title: {
            color: theme.palette.text.primary,
            cursor: 'pointer',
        },
        titleDetails: {
            paddingLeft: theme.spacing(6),
        },
        back: {
            position: 'absolute',
            top: theme.spacing(),
            left: 0,
        },
    }),
)

export default useStyles
