import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        businessHours: {
            textTransform: 'none',
            alignItems: 'flex-start',
        },
        day: {
            fontSize: '0.875rem',
            width: 100,
            textAlign: 'left',
        },
        today: {
            fontWeight: 600,
        },
        hours: {
            fontSize: '0.875rem',
            textAlign: 'left',
        },
        container: {
            display: 'inline-flex',
            flexDirection: 'column',
        },
        line: {
            display: 'inline-flex',
        },
    }),
)

export default useStyles
