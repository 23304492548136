import {
    Button,
    CardActions,
    Dialog,
    DialogContent,
    DialogTitle,
    Link,
    Typography,
    useMediaQuery,
} from '@material-ui/core'
import useTheme from '@material-ui/core/styles/useTheme'
import FacebookIcon from '@material-ui/icons/Facebook'
import MailIcon from '@material-ui/icons/Mail'
import PhoneIcon from '@material-ui/icons/Phone'
import { EstablishmentModel } from '@sugg-gestion/react-remplisvert'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import * as React from 'react'
import { useImgix } from '../core/services/imgix/useImgix'
import { useAddress } from '../core/services/onvaauresto/address/useAddress'
import { usePhoneNumber } from '../core/services/phone/usePhoneNumber'
import CloseButton from './closeButton'
import useStyles from './establishmentOvarDialog.css'
import DeliveryIcon from './icons/DeliveryIcon'
import Loading from './loading'

interface Props {
    open: boolean
    establishment?: EstablishmentModel
    onClose: () => void
}

const EstablishmentRemplisVertDialog: React.FC<Props> = ({ open, onClose, establishment }) => {
    const classes = useStyles()
    const { getImageUrl } = useImgix()
    const { inlineAddress } = useAddress(establishment?.address)
    const { displayPhone, validPhone, getPhoneNumber } = usePhoneNumber()
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

    const {
        sugg1144: {
            childImageSharp: { fluid: sugg1144 },
        },
    } = useStaticQuery(graphql`
        query {
            sugg1144: file(relativePath: { eq: "remplisvert.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 552) {
                        ...GatsbyImageSharpFluid_withWebp
                        ...GatsbyImageSharpFluidLimitPresentationSize
                    }
                }
            }
        }
    `)

    return (
        <Dialog
            open={open}
            onClose={onClose}
            classes={{
                paper: classes.root,
            }}
            fullScreen={fullScreen}
            itemScope
            itemType="https://schema.org/LocalBusiness"
        >
            <CloseButton handleClose={onClose} fullScreen={fullScreen} />
            <DialogTitle disableTypography itemProp="name">
                <Typography variant="h6" component="h1">
                    {establishment?.categoryName} {establishment?.name} à {establishment?.address.city}
                </Typography>
            </DialogTitle>
            <DialogContent
                classes={{
                    root: fullScreen ? classes.fullScreenContent : classes.content,
                }}
            >
                {establishment ? (
                    <>
                        <div className={classes.imageRoot}>
                            <div
                                className={classes.imageContainer}
                                itemProp="photo"
                                itemScope
                                itemType="https://schema.org/ImageObject"
                            >
                                {establishment?.photo?.url && (
                                    <img
                                        itemProp="contentUrl"
                                        src={getImageUrl(establishment?.photo?.url, {
                                            w: '600',
                                        })}
                                        alt={establishment?.name}
                                    />
                                )}
                            </div>
                        </div>
                        <div className={classes.titleContainer}>
                            <Typography
                                classes={{
                                    root: classes.name,
                                }}
                                component="h2"
                            >
                                {establishment.name}
                            </Typography>
                            <Typography
                                classes={{
                                    root: classes.category,
                                }}
                                component="h3"
                            >
                                {establishment.categoryName}
                            </Typography>
                            <Typography
                                variant="body2"
                                classes={{
                                    root: classes.address,
                                }}
                                itemProp="address"
                                itemScope
                                itemType="https://schema.org/PostalAddress"
                            >
                                {inlineAddress()}
                            </Typography>
                        </div>

                        {establishment.description && (
                            <>
                                <Typography
                                    classes={{
                                        root: classes.title,
                                    }}
                                >
                                    {establishment?.name}
                                </Typography>
                                <DialogContent>
                                    <Typography
                                        variant="body2"
                                        classes={{
                                            root: classes.description,
                                        }}
                                    >
                                        {establishment.description}
                                    </Typography>
                                </DialogContent>
                            </>
                        )}

                        {(validPhone(establishment.phone) ||
                            establishment.email ||
                            establishment.delivery ||
                            establishment.facebookPage) && (
                            <>
                                <Typography
                                    classes={{
                                        root: classes.title,
                                    }}
                                >
                                    Contacter
                                </Typography>
                                <CardActions
                                    classes={{
                                        root: classes.actions,
                                    }}
                                >
                                    {validPhone(establishment.phone) && (
                                        <Button
                                            variant="outlined"
                                            startIcon={<PhoneIcon color="primary" />}
                                            href={getPhoneNumber(establishment.phone)}
                                            itemProp="telephone"
                                        >
                                            {displayPhone(establishment.phone)}
                                        </Button>
                                    )}
                                    {establishment.email && (
                                        <Button
                                            variant="outlined"
                                            startIcon={<MailIcon color="primary" />}
                                            href={'mailto:' + establishment.email}
                                            classes={{
                                                root: classes.mailButton,
                                            }}
                                            itemProp="email"
                                        >
                                            {establishment.email}
                                        </Button>
                                    )}
                                    {establishment.delivery && (
                                        <Button
                                            variant="outlined"
                                            startIcon={<DeliveryIcon color="primary" />}
                                            href={establishment.delivery}
                                            target="_blank"
                                            rel="noopener"
                                            itemProp="acceptsReservations"
                                        >
                                            Livraison
                                        </Button>
                                    )}
                                    {establishment.facebookPage && (
                                        <Button
                                            variant="outlined"
                                            startIcon={<FacebookIcon />}
                                            href={establishment.facebookPage}
                                            target="_blank"
                                            rel="noopener"
                                            itemProp="url"
                                        >
                                            Facebook
                                        </Button>
                                    )}
                                </CardActions>
                            </>
                        )}
                        <Link
                            href="https://info.onvaauresto.fr/telecharger_lapplication/"
                            target="_blank"
                            rel="noopener"
                            title="Téléchargez l'application RemplisVert"
                        >
                            <Img
                                alt="Téléchargez l'application RemplisVert"
                                fluid={sugg1144}
                                className={classes.useRemplisVertImage}
                            />
                        </Link>
                    </>
                ) : (
                    <Loading withLogo={false} />
                )}
            </DialogContent>
        </Dialog>
    )
}

export default EstablishmentRemplisVertDialog
