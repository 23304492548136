import * as React from 'react'
import { GroupModel } from '../core/models/groupModel'
import { Button, Link } from '@material-ui/core'
import useStyles from './groupBanner.css'
import FacebookIcon from '@material-ui/icons/Facebook'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'

interface Props {
    group: GroupModel
}

const GroupBanner: React.FC<Props> = ({ group }) => {
    const classes = useStyles()
    const link = 'https://www.facebook.com/' + group.facebookGroupId
    return (
        <div className={classes.root}>
            <Link
                color="textPrimary"
                component="a"
                href={link}
                target="_blank"
                rel="noopener"
                classes={{
                    root: classes.link,
                }}
            >
                {group.name}
            </Link>
            <Button
                href={link}
                target="_blank"
                rel="noopener"
                variant="contained"
                startIcon={<FacebookIcon />}
                classes={{
                    root: classes.facebook,
                }}
            >
                Rejoindre les {group.memberCount} membres !
            </Button>
            <Button
                href={link}
                target="_blank"
                rel="noopener"
                classes={{
                    root: classes.facebookMobile,
                }}
                startIcon={<FacebookIcon />}
                endIcon={
                    <Button variant="outlined" color="primary">
                        Voir
                    </Button>
                }
                fullWidth
            >
                {group.name}
            </Button>
        </div>
    )
}

export default GroupBanner
