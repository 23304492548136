import * as React from 'react'
import { GroupModel } from '../core/models/groupModel'
import { List } from '@material-ui/core'
import GroupListItem from '../components/groupListItem'

interface Props {
    groups: Array<GroupModel>
}

const GroupList: React.FC<Props> = ({ groups }) => {
    return (
        <List dense>
            {groups.map((group: GroupModel) => (
                <GroupListItem group={group} key={group.facebookGroupId} />
            ))}
        </List>
    )
}

export default GroupList
