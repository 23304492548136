import { useEffect } from 'react'
import { SocialWindow } from '../../types/window'
import actions from '../../store/actions'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from '../../store/reducers'

declare let window: SocialWindow

const useGoogleMap = () => {
    const GATSBY_GOOGLE_MAP_API_KEY = process.env.GATSBY_GOOGLE_MAP_API_KEY
    if (!GATSBY_GOOGLE_MAP_API_KEY) {
        throw Error('You must set Google Map api key.')
    }
    const dispatch = useDispatch()
    const { isGoogleMapInitialized } = useSelector(({ app }: ApplicationState) => ({
        isGoogleMapInitialized: app.isGoogleMapInitialized,
    }))

    useEffect(() => {
        if (!isGoogleMapInitialized) {
            window.initMap = () => {
                dispatch(actions.setGoogleMapInitialized(true))
            }
            ;(function (d, s, id) {
                let js,
                    fjs = d.getElementsByTagName(s)[0]
                if (d.getElementById(id)) return
                js = d.createElement(s) as HTMLScriptElement
                js.id = id
                js.src =
                    'https://maps.googleapis.com/maps/api/js?key=' +
                    GATSBY_GOOGLE_MAP_API_KEY +
                    '&callback=initMap'
                js.onerror = () => {
                    dispatch(actions.setGoogleMapInitialized(false))
                }
                const a = fjs.parentNode
                if (a) {
                    a.insertBefore(js, fjs)
                }
            })(document, 'script', 'google-map-jssdk')
        }
    }, [GATSBY_GOOGLE_MAP_API_KEY, dispatch, isGoogleMapInitialized])

    return isGoogleMapInitialized
}

export default useGoogleMap
