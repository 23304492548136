interface ImgixParams {
    [param: string]: string
}

export const useImgix = () => {
    const width = typeof window !== 'undefined' ? window.innerWidth || 1400 : 1400
    const pixelRatio = typeof window !== 'undefined' ? window.devicePixelRatio || 1 : 1

    const getImageUrl = (image?: string, imgixParams?: ImgixParams) => {
        if (!image) {
            return undefined
        }
        const url = new URL(image)
        url.searchParams.set('rot', '0')
        url.searchParams.set('auto', 'format,compress')
        url.searchParams.set('dpr', pixelRatio.toString())
        url.searchParams.set('w', Math.round(width).toString())

        for (const param in imgixParams) {
            if (imgixParams.hasOwnProperty(param)) {
                url.searchParams.set(param, imgixParams[param])
            }
        }

        return url.toString()
    }

    return {
        getImageUrl,
    }
}
