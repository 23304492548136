import * as React from 'react'
import useStyles from './footerMobileButtons.css'
import { Button, ButtonGroup } from '@material-ui/core'
import FooterButton from './footerButton'

interface Props {
    setFormOpen: (
        form:
            | 'ff0aa335-25fc-42f8-937f-3b72673f58b8'
            | '62ef4bd7-f49f-4e63-91fa-edeb1f6e708d'
            | '83bd81f3-d3c2-4fb4-8aae-7b19276969ed',
    ) => void
}

const FooterMobileButtons: React.FC<Props> = ({ setFormOpen }) => {
    const classes = useStyles()

    return (
        <div className={classes.root}>
            <ButtonGroup
                color="primary"
                variant="contained"
                classes={{
                    root: classes.group,
                }}
            >
                <Button
                    onClick={() => {
                        setFormOpen('ff0aa335-25fc-42f8-937f-3b72673f58b8')
                    }}
                >
                    Client
                </Button>
                <Button
                    onClick={() => {
                        setFormOpen('62ef4bd7-f49f-4e63-91fa-edeb1f6e708d')
                    }}
                >
                    Restaurateur
                </Button>
                <Button
                    onClick={() => {
                        setFormOpen('83bd81f3-d3c2-4fb4-8aae-7b19276969ed')
                    }}
                >
                    Médias
                </Button>
            </ButtonGroup>
        </div>
    )
}

export default FooterMobileButtons
