import * as React from 'react'
import {
    IconButton,
    Link,
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
} from '@material-ui/core'
import FacebookIcon from '@material-ui/icons/Facebook'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import { GroupModel } from '../core/models/groupModel'

interface Props {
    group: GroupModel
}

const GroupListItem: React.FC<Props> = ({ group }) => {
    return (
        <ListItem
            button
            component="a"
            href={'https://www.facebook.com/' + group.facebookGroupId}
            target="_blank"
            rel="noopener"
            itemScope
            itemType="https://schema.org/Place"
            itemProp="url"
        >
            <span itemProp="geo" itemScope itemType="https://schema.org/GeoCoordinates">
                <meta itemProp="latitude" content={group.latitude.toString()} />
                <meta itemProp="longitude" content={group.longitude.toString()} />
            </span>
            <ListItemIcon>
                <FacebookIcon style={{ color: '#3b5998' }} />
            </ListItemIcon>
            <ListItemText
                primary={<span itemProp="name">{group.name}</span>}
                secondary={'Rejoindre les ' + group.memberCount + ' membres !'}
            />
            <ListItemSecondaryAction>
                <IconButton
                    edge="end"
                    component="a"
                    key={group.facebookGroupId}
                    href={'https://www.facebook.com/' + group.facebookGroupId}
                    target="_blank"
                    rel="noopener"
                >
                    <NavigateNextIcon />
                </IconButton>
            </ListItemSecondaryAction>
        </ListItem>
    )
}

export default GroupListItem
