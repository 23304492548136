import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(),
        },
        bottom: {
            paddingBottom: theme.spacing(3),
        },
        icon: {
            backgroundColor: theme.palette.primary.main,
            borderRadius: 32,
        },
    }),
)

export default useStyles
