import * as React from 'react'
import { useState } from 'react'
import GroupList from '../groupList'
import { Collapse } from '@material-ui/core'
import { GroupModel } from '../../core/models/groupModel'
import DrawerNavigationButton from '../../components/drawerNavigationButton'

interface Props {
    groups: Array<GroupModel>
    numPages: number
    currentPage: number
    groupsPerPage: number
}

const Navigation: React.FC<Props> = ({ numPages, currentPage, groupsPerPage, groups }) => {
    const [page, setPage] = useState(currentPage)

    const availablePages = [page]
    let nextPage = undefined
    if (page < numPages) {
        nextPage = '/' + (page + 1)
        availablePages.push(page + 1)
    }
    let previousPage = undefined
    if (page === 2) {
        previousPage = '/'
        availablePages.unshift(page - 1)
    } else if (page > 2) {
        previousPage = '/' + (page - 1)
        availablePages.unshift(page - 1)
    }

    const goToNextPage = () => {
        window.history.pushState({}, '', nextPage)
        setPage(page + 1)
    }

    const goToPreviousPage = () => {
        window.history.pushState({}, '', previousPage)
        setPage(page - 1)
    }

    return (
        <>
            {previousPage && (
                <DrawerNavigationButton to={previousPage} type="prev" onClick={goToPreviousPage} />
            )}
            <div>
                {typeof window !== 'undefined' ? (
                    availablePages.map((pageIndex) => {
                        return (
                            <Collapse in={pageIndex === page} key={pageIndex} timeout={800}>
                                <GroupList
                                    groups={groups.slice(
                                        (pageIndex - 1) * groupsPerPage,
                                        pageIndex * groupsPerPage,
                                    )}
                                />
                            </Collapse>
                        )
                    })
                ) : (
                    <GroupList groups={groups.slice((page - 1) * groupsPerPage, page * groupsPerPage)} />
                )}
            </div>
            {nextPage && <DrawerNavigationButton to={nextPage} type="next" onClick={goToNextPage} />}
        </>
    )
}

export default Navigation
