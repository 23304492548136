import { Skeleton } from '@material-ui/lab'
import { EstablishmentModel as OvarEstablishmentModel } from '@sugg-gestion/react-onvaauresto'
import { EstablishmentModel as RemplisVertEstablishmentModel } from '@sugg-gestion/react-remplisvert'
import * as React from 'react'
import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import MapComponent from '../components/mapComponent'
import MapComponentRoot from '../components/mapComponentRoot'
import { GroupModel } from '../core/models/groupModel'
import { MenuBoardEstablishmentModel } from '../core/models/menuBoardEstablishmentModel'
import useGoogleMap from '../core/services/google-map/useGoogleMap'
import { useGetSurroundingMenuBoards } from '../core/services/sugg1144/useGetSurroundingMenuBoards'
import actions from '../core/store/actions'
import { ApplicationState } from '../core/store/reducers'
import MapOverlay from './mapOverlay'
//
// let MapComponent
// if (typeof window !== 'undefined') {
//     MapComponent = lazy<ComponentType<MapComponentProps>>(() => import('../components/mapComponent'))
// }

interface Props {
    groups: Array<GroupModel>
    id?: string
    ovarSourceEstablishments?: Array<OvarEstablishmentModel>
    remplisVertSourceEstablishments?: Array<RemplisVertEstablishmentModel>
    getSurroundingOvarEstablishmentsInBounds: (
        bounds?: google.maps.LatLngBounds,
    ) => Array<OvarEstablishmentModel>
    getSurroundingRemplisVertEstablishmentsInBounds: (
        bounds?: google.maps.LatLngBounds,
    ) => Array<RemplisVertEstablishmentModel>
}

const MapContainer: React.FC<Props> = ({
    groups,
    id,
    ovarSourceEstablishments,
    remplisVertSourceEstablishments,
    getSurroundingOvarEstablishmentsInBounds,
    getSurroundingRemplisVertEstablishmentsInBounds,
}) => {
    const { zoom, defaultPosition, bounds } = useSelector(({ app }: ApplicationState) => ({
        zoom: app.zoom,
        defaultPosition: app.defaultPosition,
        bounds: app.bounds,
    }))
    const { getSurroundingMenuBoardsInBounds } = useGetSurroundingMenuBoards()
    const isGoogleMapInitialized = useGoogleMap()
    const dispatch = useDispatch()

    const onInit = (map: google.maps.Map) => {
        // console.log('onIdle')
        dispatch(actions.setBounds(map.getBounds() || undefined))
    }

    const onDragend = (map: google.maps.Map) => {
        // console.log('onDragend')
        dispatch(actions.setBounds(map.getBounds() || undefined))
    }

    const onZoom = (map: google.maps.Map) => {
        // console.log('onZoom')
        dispatch(actions.setZoom(map.getZoom()))
        dispatch(actions.setBounds(map.getBounds() || undefined))
    }

    const onClick = (map: google.maps.Map, group: GroupModel, groupBounds: google.maps.LatLngBounds) => {
        map.setCenter(groupBounds.getCenter())
        map.fitBounds(groupBounds, {
            top: 48,
        })
        map.panToBounds(groupBounds, {
            top: 48,
        })
        google.maps.event.addListenerOnce(map, 'zoom_changed', function () {
            const zoom = map.getZoom()
            if (zoom < parseInt(process.env.GATSBY_MINIMUM_ZOOM_DETAILS)) {
                map.setZoom(parseInt(process.env.GATSBY_MINIMUM_ZOOM_DETAILS))
            }
        })
        google.maps.event.addListenerOnce(map, 'bounds_changed', function () {
            dispatch(actions.setBounds(map.getBounds() || undefined))
        })
    }

    const onClickSuggProMarker = (establishment: MenuBoardEstablishmentModel) => {
        dispatch(actions.setCurrentSuggProEstablishment(establishment))
    }

    const onClickOvarMarker = (establishment: OvarEstablishmentModel) => {
        if (typeof window !== 'undefined') {
            dispatch(actions.setPreviousPath(window.location.pathname))
        }
        dispatch(actions.setCurrentOvarEstablishment(establishment))
    }
    const onClickRemplisVertMarker = (establishment: RemplisVertEstablishmentModel) => {
        if (typeof window !== 'undefined') {
            dispatch(actions.setPreviousPath(window.location.pathname))
        }
        dispatch(actions.setCurrentRemplisVertEstablishment(establishment))
    }

    let suggProEstablishments = useMemo(() => {
        if (zoom >= parseInt(process.env.GATSBY_MINIMUM_ZOOM_DETAILS)) {
            return getSurroundingMenuBoardsInBounds(bounds)
        }
        return []
    }, [bounds, getSurroundingMenuBoardsInBounds, zoom])

    let ovarEstablishments = useMemo(() => {
        if (zoom >= parseInt(process.env.GATSBY_MINIMUM_ZOOM_DETAILS)) {
            return getSurroundingOvarEstablishmentsInBounds(bounds)
        }
        return []
    }, [bounds, getSurroundingOvarEstablishmentsInBounds, zoom])

    let remplisVertEstablishments = useMemo(() => {
        if (zoom >= parseInt(process.env.GATSBY_MINIMUM_ZOOM_DETAILS)) {
            return getSurroundingRemplisVertEstablishmentsInBounds(bounds)
        }
        return []
    }, [bounds, getSurroundingRemplisVertEstablishmentsInBounds, zoom])

    return (
        <MapComponentRoot>
            <MapOverlay />
            {typeof window !== 'undefined' &&
            isGoogleMapInitialized &&
            ((id !== undefined &&
                ovarSourceEstablishments !== undefined &&
                remplisVertSourceEstablishments !== undefined) ||
                id === undefined) ? (
                <MapComponent
                    defaultZoom={zoom}
                    defaultPosition={defaultPosition}
                    groups={groups}
                    onDragend={onDragend}
                    onInit={onInit}
                    onZoom={onZoom}
                    onClickZone={onClick}
                    suggProEstablishments={suggProEstablishments}
                    ovarEstablishments={ovarEstablishments}
                    remplisVertEstablishments={remplisVertEstablishments}
                    onClickSuggProMarker={onClickSuggProMarker}
                    onClickOvarMarker={onClickOvarMarker}
                    onClickRemplisVertMarker={onClickRemplisVertMarker}
                />
            ) : (
                <Skeleton variant="rect" width="100%" height="100%" />
            )}
        </MapComponentRoot>
    )
}

export default MapContainer
