import * as React from 'react'
import { Toolbar, useMediaQuery } from '@material-ui/core'
import useTheme from '@material-ui/core/styles/useTheme'
import useStyles from './footer.css'

interface Props {
    rightContent: React.ReactNode
}

const Footer: React.FC<Props> = ({ children, rightContent }) => {
    const classes = useStyles()
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

    return (
        <Toolbar
            classes={{
                root: classes.root,
            }}
            variant="dense"
            disableGutters={isMobile}
        >
            <div className={classes.main}>{children}</div>
            <div className={classes.right}>{rightContent}</div>
        </Toolbar>
    )
}
export default Footer
