import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
        },
        contact: {
            marginRight: theme.spacing(),
        },
        link: {
            'margin': theme.spacing(0, 1),
            'textTransform': 'none',
            // textAlign: 'center',
            'borderRadius': 20,
            '& .MuiButton-iconSizeMedium > *:first-child': {
                marginTop: -20,
                marginBottom: -20,
                marginLeft: -14,
                marginRight: 0,
                fontSize: 40,
            },
        },
        mobile: {
            textTransform: 'none',
        },
    }),
)

export default useStyles
