import * as turf from '@turf/turf'
import { filter, find, isEqual } from 'lodash'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { generateError } from '../../helpers/errors'
import { SurroundingMenuBoardsRequest, SurroundingMenuBoardsResponse } from '../../resources/menuBoards/types'
import { queries } from '../../resources/queries'
import actions from '../../store/actions'
import { ApplicationState } from '../../store/reducers'

interface Region {
    latitude: number
    longitude: number
    latitudeDelta: number
    longitudeDelta: number
}

export const useGetSurroundingMenuBoards = () => {
    const [inProgress, setInProgress] = useState(false)
    const { surroundingMenuBoards, coveredArea } = useSelector(({ menuBoards }: ApplicationState) => ({
        surroundingMenuBoards: menuBoards.surroundingMenuBoards,
        coveredArea: menuBoards.coveredArea,
    }))
    const dispatch = useDispatch()

    /**
     * Takes Menu Boards within region
     *
     * @param region Region The region
     */
    const getSurroundingMenuBoardsInRegion = (region: Region) => {
        // Takes only menu boards within region
        const x1 = region.longitude - region.longitudeDelta / 2
        const y1 = region.latitude - region.latitudeDelta / 2
        const x2 = region.longitude + region.longitudeDelta / 2
        const y2 = region.latitude + region.latitudeDelta / 2

        return filter(surroundingMenuBoards, (surroundingMenuBoard) => {
            const lng = surroundingMenuBoard.location.lng
            const lat = surroundingMenuBoard.location.lat
            return lng > x1 && lng < x2 && lat > y1 && lat < y2
        }).sort((a, b) => {
            // Sort them from closest to furthest
            const a1 = region.longitude - a.location.lng
            const a2 = region.latitude - a.location.lat
            const b1 = region.longitude - b.location.lng
            const b2 = region.latitude - b.location.lat
            return Math.hypot(a1, a2) - Math.hypot(b1, b2)
        })
    }

    /**
     * Takes Menu Boards within region
     *
     * @param bounds google.maps.LatLngBounds The region
     */
    const getSurroundingMenuBoardsInBounds = (bounds?: google.maps.LatLngBounds) => {
        if (!bounds) {
            return []
        }
        return filter(surroundingMenuBoards, (surroundingMenuBoard) => {
            const lng = surroundingMenuBoard.location.lng
            const lat = surroundingMenuBoard.location.lat
            return bounds.contains(new google.maps.LatLng(lat, lng))
        })
    }

    const getSurroundingMenuBoards = (
        payload: SurroundingMenuBoardsRequest,
    ): Promise<SurroundingMenuBoardsResponse> => {
        if (coveredArea) {
            const points = turf.points([
                [payload.northEast.longitude, payload.northEast.latitude],
                [payload.southWest.longitude, payload.southWest.latitude],
            ])
            const ptsWithin = turf.pointsWithinPolygon(points, coveredArea)
            if (isEqual(ptsWithin, points)) {
                return new Promise<SurroundingMenuBoardsResponse>((resolve) => resolve(surroundingMenuBoards))
            }
        }

        setInProgress(true)
        return queries
            .getSurroundingMenuBoards(payload)
            .then((response) => {
                dispatch(
                    actions.setSurroundingMenuBoards({
                        surrounding: response,
                        payload,
                    }),
                )
                setInProgress(false)
                return response
            })
            .catch((error) => {
                setInProgress(false)
                throw generateError(error)
            })
    }

    const getMenuBoardById = (id: number) => {
        return find(surroundingMenuBoards, { id: id })
    }

    return {
        getSurroundingMenuBoards,
        inProgress,
        surroundingMenuBoards,
        getSurroundingMenuBoardsInRegion,
        getSurroundingMenuBoardsInBounds,
        getMenuBoardById,
    }
}
