import * as React from 'react'
import { IconButton, useMediaQuery } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import useStyles from './closeButton.css'
import useTheme from '@material-ui/core/styles/useTheme'
import clsx from 'clsx'

interface Props {
    handleClose: () => void
    fullScreen?: boolean
}

const CloseButton: React.FC<Props> = ({ handleClose, fullScreen = false }) => {
    const classes = useStyles()
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
    return (
        <IconButton
            onClick={handleClose}
            classes={{
                root: clsx(classes.close, {
                    [classes.fullScreen]: fullScreen,
                }),
            }}
            size={isMobile ? 'small' : 'medium'}
        >
            <CloseIcon />
        </IconButton>
    )
}

export default CloseButton
