import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(0, 0.5),
        },
        group: {
            '& .MuiButton-label': {
                fontSize: '0.54rem',
            },
        },
    }),
)

export default useStyles
