import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            'height': '100%',
            'width': '100%',
            'flexGrow': 1,
            '& .cluster': {
                'outline': 'none !important',
                '& > div': {
                    display: 'inline-flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: '1rem !important',
                    fontFamily: theme.typography.fontFamily + ' !important',
                    fontWeight: '600 !important',
                    color: theme.palette.primary.main + ' !important',
                },
            },
        },
    }),
)

export default useStyles
