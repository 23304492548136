import { AddressModel } from '../../models/addressModel'

export const useAddress = (address?: AddressModel) => {
    const inlineAddress = () => {
        const list = []
        if (address.street_1) {
            list.push(address.street_1)
        }
        if (address.street_2) {
            list.push(address.street_2)
        }
        if (address.zip_code) {
            list.push(address.zip_code)
        }
        if (address.city) {
            list.push(address.city)
        }
        return list.join(' ')
    }

    return {
        inlineAddress,
    }
}
