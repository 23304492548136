import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            position: 'absolute',
            top: 0,
            zIndex: 1,
            left: 0,
            right: 0,
            backgroundColor: theme.palette.primary.main,
            [theme.breakpoints.down('sm')]: {
                backgroundColor: theme.palette.secondary.main,
            },
        },
    }),
)

export default useStyles
