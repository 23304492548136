import * as React from 'react'
import { Button } from '@material-ui/core'
import FacebookIcon from '@material-ui/icons/Facebook'
import useStyles from './supportUs.css'
import clsx from 'clsx'

interface Props {
    href: string
}

const SupportUs: React.FC<Props> = ({ href }) => {
    const classes = useStyles()
    return (
        <>
            <Button
                color="primary"
                variant="contained"
                href={href}
                target="_blank"
                rel="noopener"
                disableElevation
                classes={{
                    root: classes.link,
                }}
                startIcon={<FacebookIcon fontSize="large" />}
            >
                Soutenez la Page
            </Button>
            <div
                className={clsx('fb-like', classes.like)}
                data-href={href}
                data-width=""
                data-layout="button_count"
                data-action="like"
                data-size="large"
                data-share="false"
            />
        </>
    )
}

export default SupportUs
