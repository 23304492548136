import * as React from 'react'
import useStyles from './mapOverlayContainer.css'
import { Toolbar, useMediaQuery } from '@material-ui/core'
import useTheme from '@material-ui/core/styles/useTheme'

const MapOverlayContainer: React.FC = ({ children }) => {
    const classes = useStyles()
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
    return (
        <Toolbar
            classes={{
                root: classes.root,
            }}
            variant="dense"
            disableGutters={isMobile}
        >
            {children}
        </Toolbar>
    )
}

export default MapOverlayContainer
