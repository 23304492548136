import * as React from 'react'
import { Button, Dialog, DialogContent, DialogTitle, Link, useMediaQuery } from '@material-ui/core'
import { MenuBoardEstablishmentModel } from '../core/models/menuBoardEstablishmentModel'
import useStyles from './establishmentDialog.css'
import CloseButton from './closeButton'
import useTheme from '@material-ui/core/styles/useTheme'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'

interface Props {
    open: boolean
    establishment?: MenuBoardEstablishmentModel
    onClose: () => void
    onOpenDetails: () => void
}

const EstablishmentDialog: React.FC<Props> = ({ open, onClose, establishment, onOpenDetails }) => {
    const classes = useStyles()
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
    return (
        <Dialog
            open={open}
            onClose={onClose}
            classes={{
                paper: classes.root,
            }}
            fullScreen={fullScreen}
        >
            <CloseButton handleClose={onClose} fullScreen={fullScreen} />
            <DialogTitle>
                <Link
                    onClick={onOpenDetails}
                    classes={{
                        root: classes.title,
                    }}
                >
                    <>{establishment?.name}</>
                </Link>
                <Button
                    variant="outlined"
                    color="primary"
                    classes={{
                        root: classes.moreInfo,
                    }}
                    endIcon={<NavigateNextIcon />}
                    onClick={onOpenDetails}
                >
                    plus d'info
                </Button>
            </DialogTitle>
            <DialogContent
                classes={{
                    root: fullScreen ? classes.fullScreenContent : classes.content,
                }}
            >
                {establishment && (
                    <iframe
                        src={
                            process.env.GATSBY_RESTAURANT_ENDPOINT +
                            '/menu/' +
                            establishment.restaurantOwner +
                            '?ovar=1'
                        }
                        frameBorder="0"
                        // @ts-ignore
                        border="0"
                        cellSpacing="0"
                    />
                )}
            </DialogContent>
        </Dialog>
    )
}

export default EstablishmentDialog
