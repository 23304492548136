import * as React from 'react'
import { ComponentType } from 'react'
import useFacebookSdk from '../../core/services/facebook/useFacebookSdk'

const withFacebook = (ComposedComponent: ComponentType) => {
    const WithFacebook: React.FC = ({ ...props }) => {
        useFacebookSdk()
        return <ComposedComponent {...props} />
    }
    return WithFacebook
}

export default withFacebook
