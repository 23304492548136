import * as React from 'react'
import useStyles from './footerButton.css'
import HiddenCss from '@material-ui/core/Hidden/HiddenCss'
import { Button } from '@material-ui/core'

interface Props {
    startIcon: React.ReactNode
    onClick?: () => void
    label: string
    smallLabel: string
    href?: string
    rel?: string
    target?: string
}

const FooterButton: React.FC<Props> = ({ startIcon, onClick, label, smallLabel, ...others }) => {
    const classes = useStyles()

    return (
        <Button
            color="primary"
            variant="contained"
            onClick={onClick}
            classes={{
                root: classes.link,
            }}
            startIcon={startIcon}
            {...others}
        >
            <HiddenCss xsDown>{label}</HiddenCss>
            <HiddenCss smUp>{smallLabel}</HiddenCss>
        </Button>
    )
}

export default FooterButton
