import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect, useState } from 'react'
import { useEstablishmentsList, actions } from '@sugg-gestion/react-remplisvert'
import { ApplicationState } from '../../../store/reducers'
import { filter } from 'lodash'

export const useEstablishments = () => {
    const { establishments } = useSelector(({ remplisVert: { establishments } }: ApplicationState) => ({
        establishments: establishments.establishments,
    }))
    const dispatch = useDispatch()
    const [inProgress, setInProgress] = useState(false)

    const { establishmentsList } = useEstablishmentsList()
    useEffect(() => {
        if (establishments === undefined && !inProgress) {
            setInProgress(true)
            establishmentsList()
                .then(() => {
                    setInProgress(false)
                })
                .catch((error) => {
                    dispatch(actions.setEstablishments([]))
                    setInProgress(false)
                    console.error(error)
                })
        }
    }, [establishments, inProgress, establishmentsList, dispatch])

    /**
     * Takes Menu Boards within region
     *
     * @param bounds google.maps.LatLngBounds The region
     */
    const getSurroundingEstablishmentsInBounds = useCallback(
        (bounds?: google.maps.LatLngBounds) => {
            if (!bounds) {
                return []
            }
            return filter(establishments, (establishment) => {
                const lng = establishment.location.longitude
                const lat = establishment.location.latitude
                return bounds.contains(new google.maps.LatLng(lat, lng))
            })
        },
        [establishments],
    )

    return { establishments, inProgress, getSurroundingEstablishmentsInBounds }
}
