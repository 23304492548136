import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            position: 'relative',
            height: '100%',
            width: '100%',
            display: 'flex',
            flexGrow: 1,
            flexDirection: 'column',
        },
    }),
)

export default useStyles
