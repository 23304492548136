import * as React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import { Link, Paper, useMediaQuery } from '@material-ui/core'
import useTheme from '@material-ui/core/styles/useTheme'

const Banner: React.FC = () => {
    const {
        topBanner: {
            childImageSharp: { fluid: topBanner },
        },
        topBannerSm: {
            childImageSharp: { fluid: topBannerSm },
        },
    } = useStaticQuery(graphql`
        query {
            topBanner: file(relativePath: { eq: "top-banner.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 360) {
                        ...GatsbyImageSharpFluid_withWebp
                        ...GatsbyImageSharpFluidLimitPresentationSize
                    }
                }
            }
            topBannerSm: file(relativePath: { eq: "banner.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 600) {
                        ...GatsbyImageSharpFluid_withWebp
                        ...GatsbyImageSharpFluidLimitPresentationSize
                    }
                }
            }
        }
    `)
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
    return (
        <Paper
            variant="elevation"
            square
            elevation={1}
            style={{ height: isMobile ? topBannerSm.height : topBanner.height }}
        >
            <Link href="https://info.onvaauresto.fr/">
                <Img alt="On Va Au Resto" fluid={isMobile ? topBannerSm : topBanner} />
            </Link>
        </Paper>
    )
}

export default Banner
