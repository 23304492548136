import * as React from 'react'
import { Link } from 'gatsby'
import { Button } from '@material-ui/core'
import useStyles from './drawerNavigationButton.css'
import clsx from 'clsx'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'

interface Props {
    to: string
    type: 'next' | 'prev'
    onClick: () => void
}

const DrawerNavigationButton: React.FC<Props> = ({ to, type, onClick }) => {
    const classes = useStyles()
    const scrollToTop = () => {
        const elements = document.getElementsByClassName('MuiDrawer-paperAnchorLeft')
        Array.prototype.forEach.call(elements, (element) => {
            element.scrollTo({ top: 0, behavior: 'smooth' })
        })
    }
    return (
        <div
            className={clsx(classes.root, {
                [classes.bottom]: type === 'next',
            })}
        >
            <Button
                component={Link}
                to={to}
                rel={type}
                onClick={(event) => {
                    event.preventDefault()
                    onClick()
                    scrollToTop()
                }}
                replace
                variant="outlined"
                color="primary"
                fullWidth
                endIcon={
                    type === 'next' ? (
                        <ExpandMoreIcon color="secondary" className={classes.icon} fontSize="large" />
                    ) : (
                        <ExpandLessIcon color="secondary" className={classes.icon} fontSize="large" />
                    )
                }
            >
                {type === 'next' ? 'Groupes suivant' : 'Groupes précédent'}
            </Button>
        </div>
    )
}

export default DrawerNavigationButton
