import * as React from 'react'
import { Dialog, DialogContent, useMediaQuery } from '@material-ui/core'
import useTheme from '@material-ui/core/styles/useTheme'
import HubspotForm from './hubspotForm'
import CloseButton from './closeButton'

interface Props {
    open: boolean
    formId: string
    onClose: () => void
}

const HubspotDialogForm: React.FC<Props> = ({ open, formId, onClose }) => {
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
    return (
        <Dialog open={open} fullWidth scroll="body" onClose={onClose} fullScreen={fullScreen}>
            <CloseButton handleClose={onClose} />
            <DialogContent>
                <HubspotForm formId={formId} />
            </DialogContent>
        </Dialog>
    )
}

export default HubspotDialogForm
