import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from '../../store/reducers'
import { useEffect, useState } from 'react'
import { setEstablishment } from '../../store/sugg1144/establishments/actions'
import { queries } from '../../resources/queries'
import { generateError } from '../../helpers/errors'

export const useEstablishment = (establishmentId: number) => {
    const dispatch = useDispatch()
    const { establishments } = useSelector(({ establishments }: ApplicationState) => ({
        establishments: establishments.establishments,
    }))
    const [inProgress, setInProgress] = useState(false)

    const establishment = establishments.find((current) => current.id === establishmentId)

    useEffect(() => {
        if (establishment === undefined) {
            setInProgress(true)
            queries
                .getEstablishment({
                    id: establishmentId,
                })
                .then((response) => {
                    dispatch(setEstablishment(response))
                    setInProgress(false)
                    return response
                })
                .catch((error) => {
                    setInProgress(false)
                    throw generateError(error)
                })
        }
    }, [establishment])
    return {
        inProgress,
        establishment,
    }
}
