export const UnexpectedError = 'UnexpectedError'

export class APIError extends Error {
    type: string
    data: any

    constructor(type: string = UnexpectedError, message?: string, data?: any) {
        super(message ? message : type)
        this.name = type
        this.type = type
        this.data = data
    }
}

export default APIError
