import * as React from 'react'
import { Dialog, DialogTitle, IconButton, Slide, useMediaQuery } from '@material-ui/core'
import { MenuBoardEstablishmentModel } from '../core/models/menuBoardEstablishmentModel'
import useStyles from './establishmentDialog.css'
import CloseButton from './closeButton'
import useTheme from '@material-ui/core/styles/useTheme'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import { TransitionProps } from '@material-ui/core/transitions'
import EstablishmentDetails from './establishmentDetails'

interface Props {
    open: boolean
    establishment?: MenuBoardEstablishmentModel
    onClose: () => void
    onBack: () => void
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="left" ref={ref} {...props} />
})

const EstablishmentDetailsDialog: React.FC<Props> = ({ open, onClose, onBack, establishment }) => {
    const classes = useStyles()
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
    return (
        <Dialog
            open={open}
            onClose={onClose}
            classes={{
                paper: classes.root,
            }}
            TransitionComponent={Transition}
            fullScreen={fullScreen}
        >
            <CloseButton handleClose={onClose} fullScreen={fullScreen} />
            <DialogTitle
                classes={{
                    root: classes.titleDetails,
                }}
            >
                <IconButton
                    onClick={onBack}
                    classes={{
                        root: classes.back,
                    }}
                >
                    <NavigateBeforeIcon />
                </IconButton>
                {establishment?.name}
            </DialogTitle>
            {establishment && <EstablishmentDetails menuBoardEstablishment={establishment} />}
        </Dialog>
    )
}

export default EstablishmentDetailsDialog
