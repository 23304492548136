import * as React from 'react'
import { useState } from 'react'
import InfoIcon from '@material-ui/icons/Info'
import HubspotDialogForm from '../components/hubspotDialogForm'
import FooterButton from '../components/footerButton'
import useTheme from '@material-ui/core/styles/useTheme'
import { useMediaQuery } from '@material-ui/core'
import FooterMobileButtons from '../components/footerMobileButtons'
import BurgerIcon from '../components/icons/BurgerIcon'

const Contact: React.FC = () => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
    const [formOpen, setFormOpen] = useState<
        | 'ff0aa335-25fc-42f8-937f-3b72673f58b8'
        | '62ef4bd7-f49f-4e63-91fa-edeb1f6e708d'
        | '83bd81f3-d3c2-4fb4-8aae-7b19276969ed'
        | undefined
    >(undefined)

    const handleClose = () => {
        setFormOpen(undefined)
    }

    if (isMobile) {
        return (
            <>
                <FooterMobileButtons setFormOpen={setFormOpen} />
                {formOpen && <HubspotDialogForm open={true} formId={formOpen} onClose={handleClose} />}
            </>
        )
    }
    return (
        <>
            <FooterButton
                href="https://info.onvaauresto.fr/telecharger_lapplication/"
                startIcon={<InfoIcon />}
                label="Téléchargez l'appli"
                smallLabel="Téléchargez"
            />
            <FooterButton
                href="https://info.onvaauresto.fr/"
                startIcon={<InfoIcon />}
                label="Vous êtes Restaurateur"
                smallLabel="Restaurateur"
            />
            <FooterButton
                onClick={() => {
                    setFormOpen('83bd81f3-d3c2-4fb4-8aae-7b19276969ed')
                }}
                startIcon={<InfoIcon />}
                label="Dans les médias"
                smallLabel="Médias"
            />
            {formOpen && <HubspotDialogForm open={true} formId={formOpen} onClose={handleClose} />}
        </>
    )
}

export default Contact
