import { useEffect, useState } from 'react'
import { SocialWindow } from '../../types/window'

declare let window: SocialWindow

const useFacebookSdk = () => {
    const GATSBY_FACEBOOK_APP_ID = process.env.GATSBY_FACEBOOK_APP_ID
    if (!GATSBY_FACEBOOK_APP_ID) {
        throw Error('You must set Facebook app id.')
    }
    const [isInitialized, setIsInitialized] = useState<boolean>(false)

    useEffect(() => {
        window.fbAsyncInit = () => {
            FB.init({
                appId: GATSBY_FACEBOOK_APP_ID,
                cookie: true,
                xfbml: true,
                autoLogAppEvents: true,
                version: 'v6.0',
            })
            setIsInitialized(true)
        }
        ;(function (d, s, id) {
            let js,
                fjs = d.getElementsByTagName(s)[0]
            if (d.getElementById(id)) return
            js = d.createElement(s) as HTMLScriptElement
            js.id = id
            js.defer = true
            js.src = 'https://connect.facebook.net/fr_FR/sdk.js'
            js.onerror = () => {
                setIsInitialized(true)
            }
            const a = fjs.parentNode
            if (a) {
                a.insertBefore(js, fjs)
            }
        })(document, 'script', 'facebook-jssdk')
    }, [isInitialized, GATSBY_FACEBOOK_APP_ID])

    return isInitialized
}

export default useFacebookSdk
