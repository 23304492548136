import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        imageContainer: {
            'position': 'relative',
            'width': '100%',
            'height': 0,
            'paddingTop': '40%',
            'background-size': 'cover',
            'backgroundPosition': 'center',
            'backgroundRepeat': 'no-repeat',
        },
        name: {
            fontSize: '1.2rem',
            fontWeight: 600,
        },
        address: {
            fontStyle: 'italic',
        },
        description: {
            whiteSpace: 'pre-line',
        },
        title: {
            margin: theme.spacing(1, 0),
            padding: theme.spacing(1, 3),
            backgroundColor: '#F0F0F0',
            [theme.breakpoints.down('xs')]: {
                fontSize: '0.9rem',
            },
        },
        actions: {
            'justifyContent': 'center',
            'flexWrap': 'wrap',
            '& .MuiButton-root': {
                marginBottom: theme.spacing(0.5),
            },
        },
        mailButton: {
            textTransform: 'none',
        },
        sugg1144Container: {
            paddingBottom: theme.spacing(),
            paddingLeft: theme.spacing(),
            paddingRight: theme.spacing(),
        },
        sugg1144ButtonContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: theme.spacing(),
        },
        sugg1144Button: {
            [theme.breakpoints.down('xs')]: {
                width: 160,
                paddingLeft: 0,
                paddingRight: 0,
            },
        },
        sugg1144: {
            textTransform: 'none',
            flexWrap: 'wrap',
            fontSize: '1rem',
            paddingRight: 96,
            padding: theme.spacing(1, 0),
            position: 'relative',
            [theme.breakpoints.down('xs')]: {
                paddingRight: 50,
            },
        },
        useSugg1144Logo: {
            width: 88,
            position: 'absolute',
            right: 0,
            [theme.breakpoints.down('xs')]: {
                width: 46,
                right: 2,
            },
        },
        useSugg1144Image: {
            maxWidth: '100%',
        },
    }),
)

export default useStyles
